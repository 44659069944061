.react-select-container {
  .react-select__control {
    height: 36px;
    border-radius: 7px;
    min-height: auto;

    border-color: $gray-400;

    &--is-focused,
    &--is-menu-open {
      color: $input-focus-color;
      background-color: $input-focus-bg;
      border-color: $input-focus-border-color !important;
      box-shadow: $input-box-shadow, $input-focus-box-shadow;
    }
  }

  .react-select__value-container {
    padding: $input-padding-y $input-padding-x;

    > div {
      padding-top: 0;
      padding-bottom: 0;
      margin: 0 4px 0 0;
    }
  }

  .react-select__indicator {
    padding: $input-padding-y;
  }

  .react-select__option {
    padding-bottom: $input-padding-y;
    padding-top: $input-padding-y;
    cursor: pointer;
  }

  .react-select__multi-value__remove:hover {
    background: transparent;
    color: $black;
    cursor: pointer;
  }
}

.input-group > .react-select-container {
  flex: 1 1 auto;
}

.react-select__indicator-separator {
  display: none;
}

.react-select__dropdown-indicator {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 22px;
  height: 22px;
  margin-right: 7px;
  background-color: #f8f8f8;
  border: solid 1px $black;
  border-radius: 7px;

  & svg {
    fill: black;
  }
}

.react-select__menu {
  padding: 11px;
  border-radius: 6px !important;
  box-shadow: 0 3px 6px rgba($black, 0.16) !important;
}

.react-select__menu-list {
  padding-right: 3px;
}

.react-select__option {
  display: flex !important;
  align-items: center !important;
  height: 36px;
  margin-bottom: 4px;
  padding: 0 13px !important;
  border: solid 1px #dedede;
  border-radius: 6px;
}

.react-select__option--is-focused,
.react-select__option--is-selected {
  color: $black !important;
  background-color: #f4f4f4 !important;
}

.react-select__placeholder {
  font-weight: 400;
  color: $gray-400!important;
}
