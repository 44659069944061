@each $color, $value in $theme-colors {
  .modal-#{$color} {
    @include modal-variant($value);
  }
}

.modal-colored {
  .modal-header,
  .modal-footer {
    border-color: rgba(255, 255, 255, 0.33);
  }
}

.modal-content {
  border-radius: 12px;
}

.modal-title {
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-size: 20px;
  font-weight: 500;

  &-small {
    margin-left: 10px;
    font-size: 13px;
    color: $gray-400;
  }
}

.modal-dividing {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 15px;
  border-bottom: solid 1px $gray-400;
}

.modal-header {
  padding: 29px 32px;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 1044px;
    margin: 15px auto;
  }
}
