.tab {
  margin-bottom: $spacer * 2;

  .nav-tabs {
    border: 0;
  }

  .nav-tabs .nav-link {
    height: 36px;
    border: solid 1px $gray-400;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;

    &.active {
      background: $gray-400;
      color: $tab-link-active-color;
    }

    svg {
      width: 20px;
      height: 20px;
    }
  }

  .tab-content {
    background: $white;
    padding: $tab-content-padding;
    box-shadow: $box-shadow;
    border-radius: $tab-content-border-radius;

    p:last-child {
      margin-bottom: 0;
    }
  }
}

@each $color, $value in $theme-colors {
  .tab-#{$color} {
    @include tab-variant($value);
  }
}

.tab-title {
  font-size: $font-size-lg;
}

.tab-vertical .nav-tabs {
  float: left;
  flex-direction: column;

  .nav-link {
    @include border-left-radius($border-radius);
    @include border-right-radius(0);
  }
}

.tab-vertical .tab-content {
  overflow: auto;
}
