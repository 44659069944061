.btn {
  height: 36px;
  padding: 8px 10px;
  border-radius: 7px;
  font-size: 13px;
  font-weight: 500;

  &.button-wide {
    min-width: 145px;
  }
}

.btn-primary {
  color: $black !important;
  &:hover {
    border-color: $orange;
    background-color: $orange;
  }
}

.btn-secondary {
  color: $black;
  background-color: $white;
  border-color: $gray-400;

  &:hover,
  &:focus,
  &:active {
    border-color: $orange !important;
    color: $orange !important;
    background-color: $white !important;
  }
}

.btn-pill {
  border-radius: 10rem;
}

.btn-square {
  border-radius: 0;
}

.btn .feather {
  width: 14px;
  height: 14px;
}

.btn-lg .feather {
  width: 15px;
  height: 15px;
}

.btn-group > .form-control:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

// @each $color, $value in $theme-colors {
//   .btn-#{$color} {
//     @include button-color($white);
//     @include button-color-hover($white);
//   }

//   .btn-outline-#{$color} {
//     @include button-color-hover($white);
//     @include button-color-active($white);
//   }
// }

@each $color, $value in $social-colors {
  .btn-#{$color} {
    @include button-variant($value, $value);
    @include button-color($white);
    @include button-color-hover($white);
  }
}

.btn-light,
.btn-white {
  @include button-color($gray-800);
  @include button-color-hover($gray-800);
}

.btn-outline-light,
.btn-outline-white {
  @include button-color-hover($gray-800);
  @include button-color-active($gray-800);
}
