.analitics-sale {
  .spinner-wrapper {
    display: inline-table;
    margin-left: 5px;
  }
}

.filter {
  & .card-body {
    max-width: 1220px;
  }

  .row:not(:first-child) {
    margin-top: 6px;
  }
}
