.filter {
  & .card-body {
    max-width: 1220px;
  }
}

.cute {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.filter {
  .row:not(:first-child) {
    margin-top: 6px;
  }
}
