.react-datepicker {
  &__input-container {
    input {
      width: 100%;
      height: 36px;
      border: solid 1px $gray-400;
    }
  }
}

.daterange {
  .react-datepicker__input-container {
    & .date-start {
      border-radius: 7px 0 0 7px;
      border-right: none;
      background: url(~assets/img/calendar.svg) 13px center no-repeat;
    }
    & .date-end {
      border-radius: 0 7px 7px 0;
    }
  }

  .react-datepicker {
    &-wrapper {
      width: 50%;

      &:first-child input {
        padding-left: 36px;
      }
    }
  }
}

.form-control.error {
  border-color: #ff0000;
}
