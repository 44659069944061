@import "~assets/scss/1-variables/classic";

.header-page {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 22px 26px 22px;
  border-bottom: solid 1px $gray-300 !important;

  & .h3 {
    margin-bottom: 0 !important;
  }

  &__left {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 955px;

    & > * {
      margin-right: 12px;
    }
  }
}
