@import "~assets/scss/1-variables/classic";

.order-product {
  &__toolbar {
    margin-top: 20px;
  }

  & .button-block {
    display: flex;
    justify-content: flex-start;

    & .btn {
      margin-right: 10px;
    }
  }

  & .count-block {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  & .in-reserve {
    display: block;
    width: 8px;
    height: 8px;
    margin-right: 5px;
    border-radius: 50%;
    background-color: $red;
  }

  .provider-block {
    display: flex;
    align-items: center;

    & .react-select-container {
      position: relative;
      box-sizing: border-box;
      min-width: 100%;
    }

    & .popup-block {
      position: relative;

      & .provider-alert {
        margin-left: 5px;
      }

      & .provider-popup {
        position: absolute;
        display: none;
        left: 36px;
        top: 0;
        padding: 6px 11px;
        background: $white;
        border: solid 1px $gray-400;
        border-radius: 7px;

        & div {
          white-space: nowrap;
        }
      }

      &:hover .provider-popup {
        display: block;
      }
    }
  }

  & tr.error {
    background-color: $red4;
  }

  & .warehouse-container {
    display: flex;
    justify-content: end;
    align-items: center;
  }

  & .warehouse {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 17px;
    height: 17px;
    margin-left: 3px;
    border-radius: 4px;
    font-size: 10px;
    color: $white;
    background-color: $green;
  }
}
