@import "~assets/scss/1-variables/classic";

.password {
  &__save {
    margin-top: 20px;
  }

  & .card-body {
    max-width: 600px;
  }
}
