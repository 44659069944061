@import "~assets/scss/1-variables/classic";

.user {
  &__balance {
    display: flex;
    align-items: center;
    height: 37px;
    padding: 12px 14px;
    margin-left: 10px;
    border-radius: 5px;
    background-color: $cyan;
    font-weight: 500;

    & span {
      margin-left: 5px;
      font-size: 18px;

      &.red {
        color: $red;
      }
    }
  }

  &__save {
    padding: 0 22px 22px 22px;
    border-bottom: solid 1px $gray-300 !important;
  }
}
