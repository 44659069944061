.card {
  > .dataTables_wrapper .table.dataTable,
  > .table,
  > .table-responsive .table,
  > .table-responsive-sm .table,
  > .table-responsive-md .table,
  > .table-responsive-lg .table,
  > .table-responsive-xl .table {
    border-right: 0;
    border-bottom: 0;
    border-left: 0;
    margin-bottom: 0;

    td:first-child,
    th:first-child {
      border-left: 0;
      padding-left: $spacer * 1.25;
    }

    td:last-child,
    th:last-child {
      border-right: 0;
      padding-right: $spacer * 1.25;
    }

    tr:first-child td,
    tr:first-child th {
      border-top: 0;
    }

    tr:last-child td {
      border-bottom: 0;
    }
  }

  .card-header + .table {
    border-top: 0;
  }
}

.table-action {
  a {
    color: $gray-600;

    &:hover {
      color: $gray-900;
    }
  }

  .feather {
    width: 18px;
    height: 18px;
  }
}

.table > tbody > tr > td {
  vertical-align: middle;
}

.card > .dataTables_wrapper {
  .table.dataTable {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .dataTables_info {
    padding: 1rem 1.25rem;
  }

  .dataTables_paginate {
    padding: 0.6rem 1.25rem;
  }
}

.dt-bootstrap4 {
  width: calc(100% - 2px);
}

.table {
  color: $black;
  & .right {
    text-align: right;
  }
  & .center {
    text-align: center;
  }
  & .editable {
    cursor: pointer;
  }
  th {
    font-weight: 500 !important;
  }
  .bold {
    font-weight: 600;
  }

  &.pointer tr {
    cursor: pointer;
  }

  & .green {
    color: $green;
  }

  & .green-bg {
    background-color: $green;
  }

  & .red {
    color: $red;
  }

  & .red-bg {
    background-color: $red;
    color: $white;
  }
}

.table td:first-child,
.table th:first-child {
  padding-left: 22px;
}

.table td:last-child,
.table th:last-child {
  padding-right: 22px;
}

.table thead th {
  color: $black;
  background-color: $cyan;
  border: none;
}

.table td {
  border-top: none;
  border-bottom: 1px solid $cyan;
}
