@import "~assets/scss/1-variables/classic";

.action-table {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 22px;
  height: 22px;
  padding: 0;
  border: solid 1px $black;
  border-radius: 7px;
  outline: 0 !important;

  &.size-big {
    width: 31px;
    height: 31px;
  }
}
