.search-result {
  .button-more {
    margin-left: 22px;
  }

  .search-result {
    & .count {
      height: 31px;
      text-align: center;
    }
  }

  .countGroup {
    margin-right: 7px;
    & .spinner-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 31px;
      height: 31px;
    }
  }

  .modal-button {
    cursor: pointer;
  }
}
