@import "~assets/scss/1-variables/classic";

.supply-product {
  &__toolbar {
    margin-top: 20px;
  }

  & .button-block {
    display: flex;
    justify-content: flex-start;

    & .btn {
      margin-right: 10px;
    }
  }

  & .react-select-container.import {
    width: 250px;
    margin-right: 10px;
  }

  .action-table:not(:first-child) {
    margin-left: 8px;
  } 

  & tr.error {
    background-color: $red4;
  }
}
