@import "~assets/scss/1-variables/classic";

.custom-checkbox {
  position: relative;
  height: 14px;
  input {
    visibility: hidden;
    position: absolute;

    &:checked {
      & ~ label {
        background-color: $green;
        border-color: $green;
        &:before {
          background-image: url(~assets/img/check.svg) !important;
        }
      }
    }
  }

  label {
    position: relative;
    display: inline-block;
    width: 14px;
    height: 14px;
    border-radius: 3px;
    border: solid 1px $gray-400;
    color: $white;
    cursor: pointer;

    &:before {
      content: "";
      display: block;
      position: absolute;
      width: 9px;
      height: 7px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      background-repeat: no-repeat;
      background-position: center;
      background-size: 9px auto;
    }
  }
}
