@import "~assets/scss/1-variables/classic";

.filter {
  & .card-body {
    max-width: 1220px;
  }
}

.cute {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.filter {
  .row:not(:first-child) {
    margin-top: 6px;
  }

  &__type-date {
    display: inline-block;

    &-item {
      margin-left: 10px;
      border: none;
      border-radius: 5px;
      font-size: 11px;
      outline: 0 !important;
      transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    
      &:hover {
        background-color: $orange;
      }

      &.active {
        background-color: $orange;
      }
    }
  }
}
